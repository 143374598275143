<template>
  <div>
    <ContentHeader
        :class="layout.desktop + ' mb-result-content-header'"
        :title="getTitle(resultSelected)" msg=""
        color="white" fsize="30px" lineHeight="37px" :bgcolor="resultSelectedData.bgColor"/>

    <ContentHeader
        :class="layout.mobile + ' mb-result-content-header'"
        :title="getTitle(resultSelected)" msg=""
        color="white" fsize="22px" lineHeight="27px" :bgcolor="resultSelectedData.bgColor"/>

    <Progress
        class="mb-result-progress"
        :progress="progress"
        :hide-numbers="true"
        :current-points="points"
        :marker-background="markerBackground"
        :marker-label="markerLabel"
    >
    </Progress>

    <b-container>
      <b-row>
        <b-col cols="12" class="mb-result-desc mb-result-desc-2" v-html="getDesc2(resultSelected)"></b-col>
      </b-row>
    </b-container>

    <b-button to="/contact" class="mb-result-attention-line" v-if="points > 0">
      <img src="@/assets/images/icons/telephone_white.svg" alt="Telephone">
      {{$t('global.safeometer_helplines')}}
    </b-button>

    <b-button to="/test" class="mb-result-re-do" v-if="points === 0">
      {{$t('test_page.results.safeometer_test_results_reDO')}}
    </b-button>

    <b-container>
      <b-row>
        <b-col cols="12" class="mb-result-desc mb-result-desc-1" v-html="getDesc1(resultSelected)"></b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
import ContentHeader from "@/components/ContentHeader";
import { BContainer } from 'bootstrap-vue';
import { BRow } from 'bootstrap-vue';
import { BCol } from 'bootstrap-vue';
import Progress from "@/components/Progress";

export default {
  name: "Result",
  props: ["progress", "points", "markerBackground", "markerLabel", "higherQuestionType"],
  components: {
    ContentHeader, Progress,
    'b-container': BContainer,
    'b-row': BRow,
    'b-col': BCol,
  },
  mounted() {

    if(this.points === 0){
      this.resultSelected = 'good';
      this.resultSelectedData = this.results.good;
    }else if(this.higherQuestionType === 'warn' && this.points > 0){
      this.resultSelected = 'warn';
      this.resultSelectedData = this.results.warning;
    }else if(this.higherQuestionType === 'react' && this.points > 0){
      this.resultSelected = 'react';
      this.resultSelectedData = this.results.react;
    }else if(this.higherQuestionType === 'urgent' && this.points > 0){
      this.resultSelected = 'urgent';
      this.resultSelectedData = this.results.urgent;
    }

  },
  methods: {
    getTitle(resultSelected){
      switch (resultSelected) {
        case 'good':
          return this.$t('test_page.results.safeometer_test_results_good_title');
        case 'warn':
          return this.$t('test_page.results.safeometer_test_results_warning_title');
        case 'react':
          return this.$t('test_page.results.safeometer_test_results_react_title');
        case 'urgent':
          return this.$t('test_page.results.safeometer_test_results_urgent_title');
      }
    },
    getDesc1(resultSelected){
      switch (resultSelected) {
        case 'good':
          return "";
        case 'warn':
          return this.$t('test_page.results.safeometer_test_results_warning_description2');
        case 'react':
          return this.$t('test_page.results.safeometer_test_results_react_description2');
        case 'urgent':
          return this.$t('test_page.results.safeometer_test_results_urgent_description2');
      }
    },
    getDesc2(resultSelected){
      switch (resultSelected) {
        case 'good':
          return this.$t('test_page.results.safeometer_test_results_good_description1');
        case 'warn':
          return this.$t('test_page.results.safeometer_test_results_warning_description1');
        case 'react':
          return this.$t('test_page.results.safeometer_test_results_react_description1');
        case 'urgent':
          return this.$t('test_page.results.safeometer_test_results_urgent_description1');
      }
    }
  },
  data(){
    return {
      layout: this.$responsiveLayout,
      resultSelected: -1,
      resultSelectedData: {},
      results:{
        good:{
          bgColor: "#009006",
          threshold: 0
        },
        warning:{
          bgColor: "#F0B000",
          threshold: 27
        },
        react:{
          bgColor: "#FF8000",
          threshold: 49
        },
        urgent:{
          bgColor: "#FF1100",
          threshold: 49
        }
      }
    }
  }
}
</script>

<style scoped>

  ::v-deep .mb-content-header {
    display: table;
    height: 150px;
    width: 100%;
  }

  ::v-deep .mb-content-header-title {
    font-family: Nunito Semibold;
    vertical-align: middle;
    display: table-cell;
    width: 100%;
  }

  .mb-result-attention-line,
  .mb-result-attention-line:hover {
    background-color: #0468B1;
    border-color: #0468B1;
  }

  .mb-result-re-do,
  .mb-result-re-do {
    background-color: #009006;
    border-color: #009006;
    border-radius: 15px;
  }

  .mb-result-attention-line,
  .mb-result-re-do > img {
    font-family: Lora;
    vertical-align: middle;
    padding: 5px 20px;
    font-weight: bold;
    border-radius: 23px;
    line-height: 29px;
    font-size: 23px;
  }

  .mb-result-attention-line > img,
  .mb-result-re-do > img {
    margin-right: 0.5em;
    height: 30px;
  }

  .mb-arabic .mb-result-attention-line > img{
    margin-right: 0;
    margin-left: 0.5em;
  }

  .mb-result-desc {
    font-family: Nunito Semibold;
    margin-bottom: 20px;
    margin-top: 20px;
    font-size: 18px;
    line-height: 20px;
    color: #48494A;
  }

  .mb-result-progress {
    margin-bottom: 60px;
  }

  @media (min-width: 0px) and (max-width: 991.98px) {
    .mb-result-desc {
      margin-top: 18px;
      font-size: 16px;
    }
    .mb-result-attention-line {
      padding: 3px 11px;
      line-height: 23px;
      font-size: 18px;
    }
  }

</style>