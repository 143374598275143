<template>
  <div>

    <div v-bind:style=" { display : (this.$i18n.locale === 'ar' ? 'none': 'block') }" >

      <div class="mb-test-percentages" v-bind:style="{display:(hideNumbers ? 'none': 'block')}">
        <div class="mb-test-percentages-number" >{{ percentageLabels[0] }}%</div>
        <div class="mb-test-percentages-number" >{{ percentageLabels[1] }}%</div>
        <div class="mb-test-percentages-number" >{{ percentageLabels[2] }}%</div>
        <div class="mb-test-percentages-number" >{{ percentageLabels[3] }}%</div>
      </div>

      <div :class="`mb-test-indicator-container ` + this.layout.desktop" class="mb-test-indicator-container">
        <div class="mb-test-indicator-background"></div>
        <div class="mb-test-indicator-progress" v-bind:style="{ background: computedBackground }"></div>
        <div class="mb-test-indicator-discover" v-bind:style="{ width: computedWidth }"></div>
        <div class="mb-test-indicator-progress-item" v-for="(n) in 10" :key="n"></div>
        <div
            class="mb-test-indicator-marker"
            v-bind:style="{
                'margin-left': computedMarginLeftDesktop,
                'margin-right': computedMarginRightDesktop,
                'display': computedDisplay
              }"
        >
          <svg version="1.1" id="Layer_1"
               xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
               x="0px" y="0px" viewBox="0 0 74.1 64.3" style="enable-background:new 0 0 74.1 64.3;"
               xml:space="preserve">
            <g>
              <polygon class="st0" v-bind:style="{ fill: computedFill }" points="37.1,3.1 2.8,61.2 71.3,61.2 	"/>
              <g>
                <path class="st1" d="M37,48.4c2,0,3.7,1.7,3.7,3.7c0,2-1.7,3.7-3.7,3.7c0,0,0,0,0,0c-2,0-3.7-1.7-3.7-3.7C33.3,50,35,48.4,37,48.4
                  z M33.6,19.7h6.8l-1,24.7h-4.9L33.6,19.7z"/>
              </g>
            </g>
            </svg>
            <div class="mb-test-indicator-marker-label" v-bind:style="{ color: computedFill }">
              {{ markerLabel }}
            </div>
        </div>
      </div>

      <div :class="`mb-test-indicator-container h-50 ` + this.layout.mobile" class="mb-test-indicator-container h-50">
        <div class="mb-test-indicator-background h-50"></div>
        <div class="mb-test-indicator-progress h-50" v-bind:style="{ background: computedBackground }"></div>
        <div class="mb-test-indicator-discover h-50" v-bind:style="{ width: computedWidth }"></div>
        <div class="mb-test-indicator-progress-item h-50" v-for="(n) in 10" :key="n"></div>
        <div
            class="mb-test-indicator-marker h-50"
            v-bind:style="{
                'margin-left': computedMarginLeftMobile,
                'margin-right': computedMarginRightMobile,
                'display': computedDisplay
              }"
        >
          <svg version="1.1" id="Layer_1"
               xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
               x="0px" y="0px" viewBox="0 0 74.1 64.3" style="enable-background:new 0 0 74.1 64.3;"
               xml:space="preserve">
            <g>
              <polygon class="st0" v-bind:style="{ fill: computedFill }" points="37.1,3.1 2.8,61.2 71.3,61.2 	"/>
              <g>
                <path class="st1" d="M37,48.4c2,0,3.7,1.7,3.7,3.7c0,2-1.7,3.7-3.7,3.7c0,0,0,0,0,0c-2,0-3.7-1.7-3.7-3.7C33.3,50,35,48.4,37,48.4
                  z M33.6,19.7h6.8l-1,24.7h-4.9L33.6,19.7z"/>
              </g>
            </g>
            </svg>
            <div class="mb-test-indicator-marker-label" v-bind:style="{ color: computedFill }">
              {{ markerLabel }}
            </div>
        </div>
      </div>

    </div>

    <div class="mb-test-arabic-progress-bar" v-bind:style=" { display : (this.$i18n.locale === 'ar' ? 'block': 'none') }">

      <div class="mb-test-percentages" v-bind:style="{display:(hideNumbers ? 'none': 'block')}">
        <div class="mb-test-percentages-number" >{{ percentageLabels[0] }}%</div>
        <div class="mb-test-percentages-number" >{{ percentageLabels[1] }}%</div>
        <div class="mb-test-percentages-number" >{{ percentageLabels[2] }}%</div>
        <div class="mb-test-percentages-number" >{{ percentageLabels[3] }}%</div>
      </div>

      <div :class="`mb-test-indicator-container ` + this.layout.desktop" class="mb-test-indicator-container">
        <div class="mb-test-indicator-background"></div>
        <div class="mb-test-indicator-progress" v-bind:style="{ background: computedArabicBackground }"></div>
        <div class="mb-test-indicator-discover" v-bind:style="{ width: computedWidth }"></div>
        <div class="mb-test-indicator-progress-item" v-for="(n) in 10" :key="n"></div>
        <div
            class="mb-test-indicator-marker"
            v-bind:style="{
                'margin-left': computedArabicMarginLeftDesktop,
                'margin-right': computedArabicMarginRightDesktop,
                'display': computedArabicDisplay
              }"
        >
          <svg version="1.1" id="Layer_1"
               xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
               x="0px" y="0px" viewBox="0 0 74.1 64.3" style="enable-background:new 0 0 74.1 64.3;"
               xml:space="preserve">
            <g>
              <polygon class="st0" v-bind:style="{ fill: computedFill }" points="37.1,3.1 2.8,61.2 71.3,61.2 	"/>
              <g>
                <path class="st1" d="M37,48.4c2,0,3.7,1.7,3.7,3.7c0,2-1.7,3.7-3.7,3.7c0,0,0,0,0,0c-2,0-3.7-1.7-3.7-3.7C33.3,50,35,48.4,37,48.4
                  z M33.6,19.7h6.8l-1,24.7h-4.9L33.6,19.7z"/>
              </g>
            </g>
            </svg>
          <div class="mb-test-indicator-marker-label" v-bind:style="{ color: computedFill }">
            {{ markerLabel }}
          </div>
        </div>
      </div>

      <div :class="`mb-test-indicator-container h-50 ` + this.layout.mobile" class="mb-test-indicator-container h-50">
        <div class="mb-test-indicator-background h-50"></div>
        <div class="mb-test-indicator-progress h-50" v-bind:style="{ background: computedArabicBackground }"></div>
        <div class="mb-test-indicator-discover h-50" v-bind:style="{ width: computedWidth }"></div>
        <div class="mb-test-indicator-progress-item h-50" v-for="(n) in 10" :key="n"></div>
        <div
            class="mb-test-indicator-marker h-50"
            v-bind:style="{
                'margin-left': computedArabicMarginLeftMobile,
                'margin-right': computedArabicMarginRightMobile,
                'display': computedArabicDisplay
              }"
        >
          <svg version="1.1" id="Layer_1"
               xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
               x="0px" y="0px" viewBox="0 0 74.1 64.3" style="enable-background:new 0 0 74.1 64.3;"
               xml:space="preserve">
            <g>
              <polygon class="st0" v-bind:style="{ fill: computedFill }" points="37.1,3.1 2.8,61.2 71.3,61.2 	"/>
              <g>
                <path class="st1" d="M37,48.4c2,0,3.7,1.7,3.7,3.7c0,2-1.7,3.7-3.7,3.7c0,0,0,0,0,0c-2,0-3.7-1.7-3.7-3.7C33.3,50,35,48.4,37,48.4
                  z M33.6,19.7h6.8l-1,24.7h-4.9L33.6,19.7z"/>
              </g>
            </g>
            </svg>
          <div class="mb-test-indicator-marker-label" v-bind:style="{ color: computedFill }">
            {{ markerLabel }}
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: "Progress",
  props: {
    progress:{},
    hideNumbers:{},
    currentPoints:{},
    markerLabel: {
      default: function (){
        return "";
      }
    },
    percentageLabels:{
      default: function () {
        return [20, 40, 60, 80]
      }
    },
    gradient:{
      default: function (){
        return [
          {
            color: "#F5FF00",
            percentage: 0
          },
          {
            color: "#F5FF00",
            percentage: 18
          },
          {
            color: "#FFE200",
            percentage: 27
          },
          {
            color: "#FF7600",
            percentage: 76
          },
          {
            color: "#FF0000",
            percentage: 100
          }
        ]
      }
    },
    arabicGradient:{
      default: function (){
        return [
          {
            color: "#FF0000",
            percentage: 0
          },
          {
            color: "#FF7600",
            percentage: 24
          },
          {
            color: "#FFE200",
            percentage: 73
          },
          {
            color: "#F5FF00",
            percentage: 82
          },
          {
            color: "#F5FF00",
            percentage: 100
          }
        ]
      }
    },
    markerBackground: {
      default: function (){
        return "#F0B000";
      }
    }
  },
  data() {
    return {
      width: `${ 100 - (this.progress > 100 ? 100 : this.progress) }%`,
      background: `linear-gradient(0.25turn, #FFE200, #FF7600, #FF0000)`,
      arabicBackground: `linear-gradient(0.25turn, #FF0000, #FF7600, #FFE200)`,
      backgroundRed: `linear-gradient(0.25turn, #FF4500, #FF0303, #800000)`,
      arabicBackgroundRed: `linear-gradient(0.25turn, #800000, #FF0303, #FF4500)`,
      layout: this.$responsiveLayout
    }
  },
  computed: {
    computedWidth: function () {
      return this.width;
    },
    computedBackground: function () {
      return this.progress > 100  ? this.backgroundRed : this.background;
    },
    computedArabicBackground: function () {
      return this.arabicBackground;
    },
    computedMarginLeftDesktop: function (){
      return this.computedMarginLeft(50);
    },
    computedMarginLeftMobile: function (){
      return this.computedMarginLeft(45);
    },
    computedMarginRightDesktop: function (){
      return this.computedMarginRight(50);
    },
    computedMarginRightMobile: function (){
      return this.computedMarginRight(45);
    },
    computedArabicMarginLeftDesktop: function (){
      return this.computedArabicMarginLeft(50);
    },
    computedArabicMarginLeftMobile: function (){
      return this.computedArabicMarginLeft(45);
    },
    computedArabicMarginRightDesktop: function (){
      return this.computedArabicMarginRight(50);
    },
    computedArabicMarginRightMobile: function (){
      return this.computedArabicMarginRight(45);
    },
    computedDisplay: function (){
      let startToShow = 0;
      return this.progress > startToShow ? 'inline-block' : 'none';
    },
    computedArabicDisplay: function (){
      let startToShow = 0;
      return this.progress > startToShow ? 'inline-block' : 'none';
    },
    computedFill: function (){
      return this.markerBackground;
    }
  },
  methods:{
    computedMarginLeft: function (offset){
      return `calc( ${ this.progress > 100  ? `100% - 20`:  this.progress }% - ${offset}px)`;
    },
    computedMarginRight: function (offset){
      return `calc( ${ this.progress > 100  ? `100% - 20`: 100 - this.progress }% - ${offset}px)`;
    },
    computedArabicMarginLeft: function (offset){
      return `calc( ${ this.progress > 100  ? `20`:  (100 - this.progress) }% - ${offset}px)`;
    },
    computedArabicMarginRight: function (offset){
      return `calc( ${ this.progress > 100  ? `80`:  this.progress }% - ${offset}px)`;
    },
    updated(progress){
      this.width = `${ 100 - (progress > 100 ? 100 : progress) }%`;
      if(progress > 100){
        let nearestMultiple = this.nearestMultiple(this.currentPoints, 20);
        this.percentageLabels[3] = nearestMultiple + 20;
        this.percentageLabels[2] = nearestMultiple;
        this.percentageLabels[1] = nearestMultiple - 40;
        this.percentageLabels[0] = nearestMultiple - 60;
      }
    },
    nearestMultiple(n, m) {
      if (m === 0) {
        return m
      }

      let remainder = n % m;
      if (remainder === 0) {
        return n
      }

      return n + m - remainder;
    }
  },
  watch: {
    'progress': function (value){
      this.updated(value);
    }
  },
  mounted() {
    if(!this.percentageLabels){
      this.percentageLabels = [20,40,60,80];
    }

    // Generate background
    this.background = "linear-gradient(0.25turn,";
    this.arabicBackground = "linear-gradient(0.25turn,";
    this.gradient.forEach(obj => {
      this.background += `${ obj.color } ${ obj.percentage }%,`
    });
    this.arabicGradient.forEach(obj => {
      this.arabicBackground += `${ obj.color } ${ obj.percentage }%,`
    });
    this.background = this.background.substring(0, this.background.length - 1);
    this.arabicBackground = this.arabicBackground.substring(0, this.arabicBackground.length - 1);
    this.background += ")";
    this.arabicBackground += ")";


  },
}
</script>

<style scoped>
  .mb-test-indicator-container {
    height: 100px;
  }
  .mb-test-percentages {
    background-color: #F2F9FF;
  }
  .mb-test-indicator-progress {
    background: linear-gradient(0.25turn, #FFE200, #FF7600, #FF0000);
    position: relative;
    height: 100px;
    width: 100%;
    top: -100px;
    left: 0;
  }
  .mb-test-indicator-discover {
    background: #DDDDDD;
    width: 100%;
    margin-left: auto;
    position: relative;
    height: 100px;
    top: -200px;
    left: 0;
  }
  .mb-test-percentages-number{
    display: inline-block;
    width: calc(100%/5);
  }
  .mb-test-indicator-progress-item {
    border-right: 1px solid #ffffff;
    display: inline-block;

    width: calc(100%/10);
    margin-top: 15px;
    height: 70px;

    position: relative;
    left: 0;
    top:-300px;
  }
  .mb-test-indicator-progress-item:last-of-type {
    border-right-width: 0;
  }
  .mb-test-indicator-background {
    background-color: #DDDDDD;
    position: relative;
    height: 100px;
    left: 0;
    top: 0;
  }
  .mb-test-indicator-marker{
    /*background-color: #bababa;*/
    position: relative;
    display: none;
    height: 100px;
    width: 100px;
    top: -350px;
  }
  .h-50{
   height: 50px !important;
  }
  .mb-test-arabic-progress-bar .mb-test-indicator-discover {
    margin-left: unset;
    margin-right: auto;
  }
  .mb-test-indicator-progress.h-50{
    top: -50px !important;
  }
  .mb-test-indicator-discover.h-50{
    top: -100px !important;
  }
  .mb-test-indicator-progress-item.h-50{
    margin-top: 10px !important;
    height: 30px !important;
    top: -150px !important;
  }
  .mb-test-indicator-marker.h-50{
    height: 90px !important;
    top: -175px !important;
    width: 90px;
  }
  .mb-test-indicator-container {

  }
  .mb-test-indicator-marker-label{
    font-family: "Nunito Semibold";
    line-height: 22px;
    font-size: 18px;
  }
  .mb-layout-mobile .mb-test-indicator-marker-label{
    line-height: 17px;
    font-size: 14px;
  }
  .st0{
    fill:#EFB01E;
    stroke:#FFFFFF;
    stroke-width:2.6695;
    stroke-miterlimit:10;}
  .st1{
    fill:#FAFBFB;
  }
</style>